
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        










































.login {
  margin: 40px auto;
}
input {
  display: block;
  margin: 10px auto;
  width: 20%;
  padding: 15px;
}
button {
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 10px;
  width: 75px;

  img {
    width: 100%;
  }
}
p {
  margin-top: 40px;
  font-size: 14px;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
