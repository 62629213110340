
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        







































h2 {
  margin-top: 0;
}
input {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
