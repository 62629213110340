
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        










































header {
  text-align: left;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 10px 40px;
  position: relative;
  z-index: 999;

  .logo {
    border: none;
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 60px;
      height: auto;
    }
  }

  nav {
    vertical-align: middle;

    li {
      display: inline-block;
      margin-right: 40px;
      vertical-align: middle;
    }

    a {
      color: #000;
      font-weight: bold;

      &.router-link-exact-active {
        color: #f00;
      }
    }
  }
}
button {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin-left: auto;
}
